<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Login-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
      <center>
        <b-col
          lg="6"
        >
          
        <div class="align-items-center">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V3"
          />
        </div>
      
        </b-col>
      </center>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      conectando: false,
      bloquearLogin: false,
      password: '',
      login: '',
      sideImg: require('@/assets/images/logo/LogoVapza.png'),
      usuario: '',
      usuarioAcessoMenu: [],
      statusMessage: '',
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        
        this.sideImg = require('@/assets/images/logo/LogoVapza.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
   
    // Verifica se Session Usuario Exite e Elimina
    if (this.$session.exists('usuario')) {
      this.$session.remove('usuario')
      // this.$session.remove('clearCache')
    }
   
  },
  methods: {
   
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
